/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import { Box, Container, Paragraph } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

const Layout: FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
          bottom: 0,
        }}
      >
        <StaticImage
          src="../images/razer-bg.jpg"
          quality={100}
          placeholder="dominantColor"
          formats={["auto", "webp", "avif"]}
          alt="Login with Razer"
          layout="fullWidth"
          style={{
            minHeight: "100vh",
            minWidth: "100vw",
          }}
        />
      </Box>
      <Container sx={{ position: "relative" }}>
        <Box as="main">{children}</Box>
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
